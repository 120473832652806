@import '~@core/scss/base/pages/page-auth.scss';
.brand-logo {
  svg {
    height: 28px;
    width: 40.95px;
  }
}
html[dir='rtl'] {
  svg.feather {
    transform: rotate(180deg);
  }
}
